import { AnalyticsProvider, useFirebaseApp } from "reactfire";
import { getAnalytics, setAnalyticsCollectionEnabled } from "firebase/analytics";
import HomePage from "./Components/HomePage";

function App() {

  const app = useFirebaseApp()

  const analyticsInstance = getAnalytics(app)
  setAnalyticsCollectionEnabled(analyticsInstance, false)
  return (
    <AnalyticsProvider sdk={analyticsInstance}>
      <HomePage />
    </AnalyticsProvider>
  );
}

export default App;
