import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FirebaseAppProvider } from "reactfire";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCXoNaGx_5s8oIHU5QqgdVTAlJ1Z-cS19E",
  authDomain: "portfolio-79696.firebaseapp.com",
  projectId: "portfolio-79696",
  storageBucket: "portfolio-79696.appspot.com",
  messagingSenderId: "364738522563",
  appId: "1:364738522563:web:aefa04dd53da7adf8a6176",
  measurementId: "G-0XQG41RBCW"
};

root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <App />
  </FirebaseAppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
